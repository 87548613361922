.view-receipt-and-accept{
    width: 50vw;
    max-height: 80vh;
}

.view-receipt-modal{
    width: 50vw;
    padding: 10px;
}

.receipt-modal-image{
    margin-top: 10px;
    width: 100%;
}

@media only screen and (max-width: 600px) {
   .view-receipt-and-accept{
    width: 95vw;
   }

   .view-receipt-modal{
    width: 95vw;
    height: 100%;
    padding: 10px;
}
}