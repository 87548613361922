.beneficiary-button-container{
    margin: 0 0 10px 0;
}

@media only screen and (max-width: 600px) {
    .beneficiary-button-container{
        display: flex;
        justify-content: space-between;
    }

    .beneficiary-submenu-button{
        width: 100px;
        font-size: 12px;
    }
}