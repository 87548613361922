.member-details-paper {
    min-height: 280px;
    padding: 20px;
}

.member-details-transaction-model {
    width: 50vw;
}

.insurance-image {
    width: 90%;
}

/* .children-table .MuiDataGrid-columnGroupHeader{
    min-height: 20px !important;
} */

@media only screen and (max-width: 600px) {
    .member-details-grid {
        margin: "20px 0"
    }

    .member-details-personal-details-section {
        flex-direction: column;
    }

    .member-details-personal-details {
        margin-left: 0px !important;
    }

    .member-details-transaction-model {
        width: 95vw;
    }


}